<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="importBatch = true">批量导入</el-button>
    </el-row>

    <el-table :data="dataList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="assetSn" label="资产码" width="220px"></el-table-column>
      <el-table-column align="center" prop="reason" label="DOA故障原因"></el-table-column>
      <el-table-column align="center" prop="doaDate" label="备案日期" width="200px"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="100px"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="200px"></el-table-column>
      <el-table-column align="center" label="操作" width="150px">
        <template slot-scope="scope">
          <el-button @click="deleteRule(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
    <el-dialog title="批量导入" :visible.sync="importBatch" :close-on-click-modal="false" width="800px" :before-close="importClose">
      <ImportComponent template-url="template/doaReason.xlsx" upload-url="doaReason/importBatch"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importClose">关闭</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import ImportComponent from "@/components/ImportComponent";

export default {
  name: "DoaReason.vue",
  components: {ImportComponent},
  data() {
    return {
      queryInfo: {
        assetSn: '',
        pageNo: 1
      },
      dataList: [],
      total: 0,
      pageSize: 0,
      loading: false,
      importBatch: false
    }
  },
  created() {
    this.search();
  },
  methods: {
    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('/doaReason/doaReasonInfo', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      console.log(res.data)
      this.dataList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    importClose() {
      this.search();
      this.importBatch = false;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    deleteRule(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('doaReason/delete', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    }
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>